import React from "react";
import { LayoutBox } from "../../../components/atoms/LayoutBox";
import Layout from "../../../components/Layout";
import { TextBanner } from "../../../components/molecules/TextBanner";

const SuccessPage = () => {
  return (
    <Layout title="Successful Form Submission">
      <div>
        <TextBanner title="Thank you" />
        <LayoutBox>
          <div className="content">
            <p>Your form has been submitted.</p>
          </div>
        </LayoutBox>
      </div>
    </Layout>
  );
};

export default SuccessPage;
